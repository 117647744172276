function importAll(r) {
  let avatars = {};
  r.keys().forEach((item) => {
    avatars[item.replace('./', '')] = r(item);
  });
  return avatars;
}

const avatars = importAll(require.context('../avatars', false, /\.(png|jpe?g|svg)$/));

export default avatars;