import { useEffect, useState } from "react"
import { useAuthContext } from "./useAuthContext"
import { signOut } from "firebase/auth"
import { projectAuth } from "../firebase/config"

export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const logout = async () => {
        setError(null)
        setIsPending(true)

        //sign out
        try{
            await signOut(projectAuth)

            //dispatch
            dispatch({ type: "LOGOUT" })

            if(!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch(err){
            if(!isCancelled) {
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(false);
    },[])

    return { logout, error, isPending}
}