import Logo from "../assets/logo.png"

//CSS
import "./Loading.css"

export default function Loading(){
  return (
    <div className="loading">
      <img src={Logo} style={{objectFit:"contain"}} />
    </div>
  )
}