import { createContext, useEffect, useReducer } from "react";
import { projectAuth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN": 
            return {...state, user: action.payload }
        case "LOGOUT": 
            return {...state, user: null }
        case "AUTH_IS_READY":
            return {...state, user: action.payload, auth_is_ready: true}
        case "CHAT":
            return {...state,chat_id: action.chat_id, chat_opened: Math.random().toString(16).slice(2)}
        case "LAUNCHSCREEN":
            return {...state, launch_screen: action.launch_screen }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        auth_is_ready: false,
        chat: null,
        chat_opened: null,
        launch_screen: true
    })

    useEffect(() => {
        const unsub = onAuthStateChanged(projectAuth, (user) => {
            dispatch({ type: "AUTH_IS_READY", payload: user})
            unsub()
        })
    },[])
    
    return (
        <AuthContext.Provider value={ {...state, dispatch } }>
            { children }
        </AuthContext.Provider>
    )
}