import "./PopupMessage.css"
import Popup from "./Popup"
import Loading from "./Loading"

export default function PopupMessage({message, button_title, callBack}){

  const refresh = () => {
    callBack()
  }

  return (
    <div className="popup-message">
      (<Popup>
          <Loading />
          <span className="message">{message}</span>
          <div className="refresh"><button className="button is-dark" onClick={refresh}>{button_title}</button></div>
      </Popup>)
    </div>
  )
}