import { useState } from "react"
import { useSignup } from "../../hooks/useSignup"
import { usePassReset } from "../../hooks/usePassReset"
import { Link, useNavigate } from "react-router-dom";

//CSS
import "./PassReset.css"

export default function PassReset(){
    const [email,setEmail] = useState("")
    const { passReset, isPending, error} = usePassReset()
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()

        await passReset(email)

        if(!error){
          navigate("/")
      }    
    }

    return (
        <div className="passreset">
            <form onSubmit={handleSubmit} className="passreset-form">
                <Link to="/" className="backhome">indietro</Link>
                <span className="title">Recupera Password</span>
                <label>
                    <span>Email</span>
                    <input type="email" required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    />
                </label>
                {!isPending && <button className="bottone">Invia Email</button>}
                {isPending && <button className="bottone" disabled>Loading</button>}
                {error && <p>{error}</p>}
            </form>
        </div>
    )
}