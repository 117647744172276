import { useEffect, useState } from "react"

//CSS
import "./BugReporting.css"
import { useFirestore } from "../../hooks/useFirestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import PopupMessage from "../../components/PopupMessage";
import { useNavigate } from "react-router-dom";

export default function BugReporting(){
    const [description, setDescription] = useState("");
    const [showPopup, setShowPopup] = useState(false)
    const { addDocument, response} = useFirestore("bug-report")
    const { user } = useAuthContext()
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0); // Resetta lo scroll alla parte superiore
    }, []);

    useEffect(() => {
      if(response.success){
        setShowPopup(true)
      }
    }, [response])

    const handleSubmit = async (e) => {
        e.preventDefault()

        await addDocument({
          email: user.email,
          uid: user.uid,
          description
        })
    }

    return (
        <div className="bugreporting">
            <form onSubmit={handleSubmit} className="bugreporting-form">
                <span className="title">Segnala un problema</span>
                <label>
                    <span>Descrizione</span>
                    <textarea onChange={(e) => setDescription(e.target.value)} required
                      value={description}
                    />
                </label>
                {!response.isPending && <button className="bottone">Segnala</button>}
                {response.isPending && <button className="bottone" disabled>Caricamento</button>}
                {response.error && <p className="error">{response.error}</p>}
                {showPopup && <PopupMessage message={"Problema segnalato"} button_title={"Ok"} callBack={() => {navigate("/")}} />}
            </form>
        </div>
    )
}