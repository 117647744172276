import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCkMpn8WWS9aQ78MHzrkZHpYfH81PRdJsM",
  authDomain: "spostiamoci-insieme.firebaseapp.com",
  projectId: "spostiamoci-insieme",
  storageBucket: "spostiamoci-insieme.appspot.com",
  messagingSenderId: "209034744339",
  appId: "1:209034744339:web:34aaa67a90bf74a72ea5e6",
  measurementId: "G-5PYHZ1X8T3"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const projectAuth = getAuth(app);
const projectFirestore = getFirestore(app);
const projectStorage = getStorage()

const timestamp = Timestamp

export { analytics, projectAuth, projectFirestore, projectStorage, timestamp }