import { useAuthContext } from "../hooks/useAuthContext"

import "./TripCardFront.css"
import Car from "../assets/avatars/car_green.png"
import Moto from "../assets/avatars/moto_green.png"
import Distance from "../assets/distance.svg"
import Calendar from "../assets/calendar.svg"
import Profile from "../assets/profile.svg"
import avatars from "../assets/avatars/avatars";

export default function TripCardFront({ trip }){

  const { user } = useAuthContext()

  return (
    <div className="trip-card-front">
        <div className="user">
            <img src={avatars[trip.createdBy.photoURL]}></img>
            <span>{trip.createdBy.displayName}</span>
        </div>
        <div className="date">
            <img className="date-icon" src={Calendar}></img>
            <span>{trip.date}</span>
        </div>
        <div className="i_place"><span>{trip.i_place}</span></div>
        <div className="i_time"><span>{trip.i_time}</span></div>
        <img className="distance-icon" src={Distance}></img>
        <div className="f_time"><span>{trip.f_time}</span></div>
        <div className="f_place"><span>{trip.f_place}</span></div>
        <div className="people">
            {trip && Object.keys(trip.people_booked).map((e, index) => {
                return (<img className="people-icon" key={index} src={Profile}></img>)
            })}
            {trip && Array.from(Array(trip.people), (e,i) => {
                return (<img className="people-icon" key={i} src={Profile} style={{opacity:"0.3"}}></img>)
            })}
        </div>
        <div className="veichle">
        <span>{trip.fuel}{trip.fuel == "Gratis" ? "" : "€"}</span>
        <img className="veichle-icon" src={trip.veichle == "Macchina" ? Car : Moto} />
        </div>
    </div>
    ) 
}