import { useEffect, useReducer, useState } from "react";
import { projectFirestore, timestamp } from "../firebase/config";
import { collection, addDoc, deleteDoc, doc, updateDoc, increment } from "firebase/firestore";

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case "IS_PENDING":
            return { isPending: true, document: null, success: false, error: null }
        case "ADDED_DOCUMENT":
            return { isPending: false, document: action.payload, success: true, error: null }
        case "DELETED_DOCUMENT":
            return { isPending: false, document: null, success: true, error: null }
        case "ERROR":
            return { isPending: false, document: null, success: false, error: action.payload}
        case "UPDATED_DOCUMENT":
            return { isPending: false, document: action.payload, success: true,  error: null }
        default:
            return state
    }
}

export const useFirestore = (custom_collection) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    const [isCancelled, setIsCancelled] = useState(false)

    //collection ref
    const ref = collection(projectFirestore, custom_collection)

    //only dispatch is not cancelled
    const dispatchIFIsNotCancelled = (action) => {
        if(!isCancelled){
            dispatch(action)
        }
    }

    //add document
    const addDocument = async (doc) => {
        dispatch({ type: "IS_PENDING" })

        try{
            const createdAt = timestamp.fromDate(new Date())
            const added_document = await addDoc(ref, { ...doc, createdAt})
            dispatchIFIsNotCancelled({ type: "ADDED_DOCUMENT", payload: added_document})
        }
        catch(err){
            dispatchIFIsNotCancelled({ type: "ERROR", payload: err.message })
            console.log(err.message)
        }
    }

    //delete document
    const deleteDocument = async (id) => {
        dispatch({ type: "IS_PENDING" })

        try{
            await deleteDoc(doc(ref, id))
            dispatchIFIsNotCancelled({ type: "DELETED_DOCUMENT" })
        }
        catch(err){
            dispatchIFIsNotCancelled({ type: "ERROR", payload: "Could not delete" })
            console.log(err.message)
        }
    }

    //update document
    const updateDocument = async (id, updated) => {
        dispatch({ type: "IS_PENDING" })

        try{
            const updatedDocument = await updateDoc(doc(ref, id),updated)
            dispatchIFIsNotCancelled({ type: "UPDATED_DOCUMENT", payload: updatedDocument })
            return updatedDocument
        }
        catch(err){
            console.log(err.message)
            dispatchIFIsNotCancelled({ type: "ERROR", payload: err })
            return null
        }
    }

    //add Trip
    const addTrip = async (param_doc, user_uid) => {
        dispatch({ type: "IS_PENDING" })

        try{
            const createdAt = timestamp.fromDate(new Date())
            const added_document = await addDoc(ref, { ...param_doc, createdAt})
            
            const ref2 = await doc(projectFirestore, 'utenti', user_uid)
            await updateDoc(ref2, {
                "viaggi": increment(1)
            })

            dispatchIFIsNotCancelled({ type: "ADDED_DOCUMENT", payload: added_document})
        }
        catch(err){
            dispatchIFIsNotCancelled({ type: "ERROR", payload: err.message })
            console.log(err.message)
        }
    }

    //delete document
    const deleteTrip = async (id, user_uid) => {
        dispatch({ type: "IS_PENDING" })

        try{
            await deleteDoc(doc(ref, id))

            const ref2 = await doc(projectFirestore, 'utenti', user_uid)
            await updateDoc(ref2, {
                "viaggi": increment(-1)
            })

            dispatchIFIsNotCancelled({ type: "DELETED_DOCUMENT" })
        }
        catch(err){
            dispatchIFIsNotCancelled({ type: "ERROR", payload: "Could not delete" })
            console.log(err.message)
        }
    }

    useEffect( () => {
        return () => setIsCancelled(false)
    }, [])

    return { addDocument, deleteDocument, updateDocument, addTrip, deleteTrip, response }
}