import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useFirestore } from '../hooks/useFirestore';
import { arrayUnion } from 'firebase/firestore';
import { useChat } from '../hooks/useChat';
import avatars from '../assets/avatars/avatars';
import Send from "../assets/send.svg"

//CSS
import './Chat.css';
import ChatIcon from "../assets/chat.svg"
import Close from "../assets/close.svg"


const Chat = () => {
  const { user, chat_id, chat_opened } = useAuthContext()
  const { updateDocument, response } = useFirestore("viaggi")
  const { chat_details, messages, error} = useChat("viaggi",chat_id)
  
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState('');

  //handleToggle
  const handleToggle = () => {
    setIsOpen(!isOpen);
    if(!isOpen && window.screen.width < 800){
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    }
    else{
      document.body.style.overflow = 'unset'
      document.body.style.position = 'unset'
    }
  };

  //handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
    if(chat_id == null) return;

    const message = {
      displayName: user.displayName,
      photoURL: user.photoURL,
      uid: user.uid,
      text: input,
      id_msg: Math.random().toString(16).slice(2)
    }
    
    await updateDocument(chat_id,{
        chat: arrayUnion(message),
    })

    if(!response.error){
      setInput("")
    }
  };

  useEffect(() => {
    if(chat_id != null){
      setIsOpen(true)
      if(!isOpen && window.screen.width < 800){
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'fixed'
      }
    }
  },[chat_opened,chat_id])

  useEffect(() => {
    let divElement = document.querySelector('.chat .chat-box .chat-logs');
    if(divElement != null){
      divElement.scrollTo(0, 1000000000000)
    }
  },[messages,isOpen])

  return (
    <div className='chat'>
      {chat_id && (<div className="chat-logo" onClick={handleToggle} style={{ display : isOpen ? 'none' : 'block'}}>
        <img src={ChatIcon} alt="Chat Icon" />
      </div>)}

      <div className="chat-box" style={{ display: isOpen ? 'block' : 'none' }}>
        <div className="header">
          {chat_details && (
          <span>{chat_details.i_place}<br/> {chat_details.f_place}</span>
          )}
          <img src={Close} className="close" onClick={handleToggle}/>
        </div>
        <div className='users'>
          {chat_details && (<span className='admin'>{chat_details.createdBy.displayName}</span>)}
          {chat_details && Object.values(chat_details.people_booked).map((p, index) => {
            return (<span className='user' key={index}>
              {p}
            </span>)
          })}
        </div>
        
        <div className="body">
          <div className="chat-logs">
            {error && (<p>{error}</p>)}
            {!messages && (<div>Nessuna chat selezionata!</div>)}
            {messages && messages.length === 0 && (<div>Nessun messaggio!</div>)}
            {messages && messages.map((msg, index) => (
              <div key={index} className={`chat-msg ${user.uid === msg.uid ? "self":"user"}`}>
                <span className="msg-avatar">
                  <img src={avatars[msg.photoURL]}alt="avatar"/>
                </span>
                <div className="msg-text">
                  {user.displayName != msg.displayName && (<div className='user-name'>{msg.displayName}</div>)}
                  {msg.text}
                  </div>
              </div>
            ))}
          </div>
        </div>

        <div className="chat-input">
          <form onSubmit={handleSubmit}>
            <input type="text" autoComplete='off' disabled={chat_id != null ? false:true} id="chat-input" placeholder="Scrivi un messaggio..." value={input} onChange={(e) => setInput(e.target.value)}/>
            <button type="submit" disabled={chat_id != null ? false:true} className="chat-submit"><img src={Send} alt='send-icon' className='send-icon' /></button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
