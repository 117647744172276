//CSS
import "./Hero.css"

export default function Hero(){
  return (
    <div className="hero">
      <div className="description">
      <span className="nowrap">Condividi il<span className="colored-text">&nbsp;viaggio</span>, risparmia e conosci nuovi<span className="colored-text">&nbsp;amici</span></span>
      </div>
    </div>
  )
}