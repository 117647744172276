import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
import { doc, onSnapshot } from "firebase/firestore";

export const useChat = (custom_collection, chat_id) => {
    const [chat_details, setChatDetails] = useState(null)
    const [messages, setMessages] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {

        //collection ref
        try{
          if(!chat_id){
            return
          }

          const ref = doc(projectFirestore, custom_collection, chat_id)
          //query        
          const unsubscribe = onSnapshot(ref, (snapshot) => {
              let results = []
              const s = snapshot.data()
              if(s){
                setChatDetails({
                  createdBy: s.createdBy,
                  people_booked: s.people_booked,
                  i_place: s.i_place,
                  f_place: s.f_place
                })
                s.chat.forEach(m => {
                  results.push(m)
                })
              }
              
              //update states
              setMessages(results)
              setError(null)
              
          }, (error) => {
              console.log(error.message)
              setError("Could not fetch the data")
          })

          //unsubscrive
          return () => unsubscribe()
        }
        catch(err){
          setError(err.message)
          console.log(err.message)
        }

    }, [custom_collection, chat_id])

    return { chat_details, messages, error }
}