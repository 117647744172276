import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useChangePass } from "../../hooks/useChangePass";
import { useAuthContext } from "../../hooks/useAuthContext";

//CSS
import "./ChangePass.css"

export default function ChangePass(){
    const [password,setPassword] = useState("")
    const [confirm_password,setConfirmPassword] = useState("")
    const [formError,setFormError] = useState(null)
    const { changePass, isPending, error} = useChangePass()
    const navigate = useNavigate()
    const { user } = useAuthContext()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormError(null)

        //Error

        if(password != confirm_password){
          setFormError("Le due password non coincidono")
          return
        }

        //ChangePass
        await changePass(user, password)

        if(!error){
          navigate("/")
      }    
    }

    return (
        <div className="changepass">
            <form onSubmit={handleSubmit} className="changepass-form">
                <span className="title">Cambia Password</span>
                <label>
                    <span>Password</span>
                    <input type="password" required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    />
                </label>
                <label>
                    <span>Conferma Password</span>
                    <input type="password" required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirm_password}
                    />
                </label>
                {!isPending && <button className="bottone">Cambia Password</button>}
                {isPending && <button className="bottone" disabled>Loading</button>}
                {error && <p className="error">{error}</p>}
                {formError && (<p className="error">{formError}</p>)}
            </form>
        </div>
    )
}