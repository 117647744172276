import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";
import Logo from "../assets/logo.png"
import { useEffect, useState } from "react";
import avatars from "../assets/avatars/avatars";
import { useChangeIcon } from "../hooks/useChangeIcon";
import EditIcon from "../assets/edit.svg"


//CSS
import "./Navbar.css"
import Plus from "../assets/plus.svg"
import Popup from "./Popup";

export default function Navbar(){
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const [isOpen, setIsOpen] = useState(false);
    const [selectedAvatar, setSelectedAvatar] = useState(user ? user.photoURL : "car_blue.png");
    const [show_avatars, setShowAvatars] = useState(false);
    const { changeIcon, isPending, error} = useChangeIcon()

    //handleToggle
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    //handleChangeIcon
    const handleChangeIcon = async (e) => {
        //change photo url
        e.preventDefault()
        await changeIcon(user, selectedAvatar)

        if(!error){
            setShowAvatars(false)
        }
    }

    useEffect(() => {
        window.onscroll = function() {
            setIsOpen(false)
    }},[])

    return (
        <div className="navbar">
            <div className="leading">
                <Link to="/">
                    <img src={Logo} alt="logo-image" className="logo-image"></img>
                    <span className="logo-title">Spostiamoci #Insieme</span>
                </Link>
            </div>
            <div className="actions">
                {!user && (
                <>
                    <Link to="/login" className="login-button">Accedi</Link>
                </>
                )}

                {user && (
                <>
                    <Link to="/newtrip" className="new-trip">
                        <img src={Plus} alt="plus-image" className="plus-image"></img>
                        <span>Nuovo Viaggio</span>
                    </Link>
                    <div className="profile-logo">
                        <img className="profile-icon" onClick={handleToggle} src={avatars[user.photoURL]} alt="profile-icon" />
                        {/* <div className="profile-floor">
                            <div className="floor"></div>
                            <div className="floor"></div>
                        </div> */}
                    </div>
                    <div className="profile-box-wrapper" onClick={handleToggle} style={{ display: isOpen ? '' : 'none' }}>
                        <div className="profile-box">
                            <div className="user">
                                <img src={EditIcon} alt="edit" className="edit-icon" onClick={() => {setShowAvatars(true)}} />
                                <img src={avatars[user.photoURL]} alt="avatar"/>
                                <span>{user.displayName}</span>
                            </div>            
                            <span>{user.email}</span>
                            <Link to="/changepass">Cambia Password</Link>
                            <Link to="/bugreporting">Segnala un problema</Link>
                            <button className="logout" onClick={logout}>Esci</button>
                    </div>
                    </div>
                </>
                )}
            </div>
            {show_avatars && (<Popup>
                <div className="popup-avatars">
                    <span>Icona</span>
                    <div className="avatars">
                        {Object.keys(avatars).map((key) => (
                            <img
                                key={key}
                                src={avatars[key]}
                                alt={key.replace(/_/g, ' ').replace(/\..+$/, '')}
                                className={`avatar ${selectedAvatar === key ? 'active' : ''}`}
                                onClick={() => setSelectedAvatar(key)}
                            />
                        ))}
                    </div>
                    <div className="buttons">
                        <button className="cancel-icon" onClick={() => {setShowAvatars(false)}}>annulla</button>
                        {!isPending && (<button className="change-icon" onClick={handleChangeIcon}>cambia</button>)}
                        {isPending && (<button className="change-icon" disabled>Caricamento</button>)}
                    </div>
                    {error && <p className="error">{error}</p>}
                </div>
            </Popup>)}
        </div>
    )
}