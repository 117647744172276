import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

//Pages
import Home from "./pages/home/Home";
import Signup from "./pages/signup/Signup";
import Login from "./pages/login/Login";
import Navbar from "./components/Navbar";
import NewTrip from "./pages/trip/NewTrip";
import Chat from "./components/Chat";
import PassReset from "./pages/passreset/PassReset";
import LaunchScreen from "./pages/launch_screen/LaunchScreen";
import Page404 from "./pages/page404/Page404";
import ChangePass from "./pages/changepass/ChangePass";
import BugReporting from "./pages/bugreporting/BugReporting";

function App() {

  const { auth_is_ready, user, launch_screen } = useAuthContext() 
  
  return (
    <div className="App">
      {auth_is_ready && (
        <BrowserRouter>
          {!launch_screen && <Navbar />}
          <Routes>
            {launch_screen && <Route path="*" element={
              <LaunchScreen />
            } />}
            {!launch_screen && <Route exact path="/" element={
              (user && <Home />) || (!user && <Navigate replace to="/login" />)
            } />}
            {!launch_screen &&<Route exact path="/login" element={
              (!user && <Login />) || (user && <Navigate replace to="/" />)
            } />}
            {!launch_screen && <Route exact path="/signup" element={
              (!user && <Signup />) || (user && <Navigate replace to="/" />)
            } />}
            {!launch_screen && <Route exact path="/passreset" element={
              (!user && <PassReset />) || (user && <Navigate replace to="/" />)
            } />}
            {!launch_screen && <Route exact path="/changepass" element={
              (user && <ChangePass />) || (!user && <Navigate replace to="/login" />)
            } />}
            {!launch_screen && <Route exact path="/newtrip" element={
              (user && <NewTrip />) || (!user && <Navigate replace to="/login" />)
            } />}
            {!launch_screen && <Route exact path="/bugreporting" element={
              (user && <BugReporting />) || (!user && <Navigate replace to="/login" />)
            } />}
            {!launch_screen && <Route path="*" element={
              <Page404 />
            } />}
         </Routes>
         {user && <Chat />}
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
