import { useEffect, useState } from "react"
import { useLogin } from "../../hooks/useLogin";

//CSS
import "./Login.css"
import { Link } from "react-router-dom";

export default function Login(){
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const { login, error, isPending} = useLogin()


    useEffect(() => {
        window.scrollTo(0, 0); // Resetta lo scroll alla parte superiore
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
        login(email, password)
    }

    return (
        <div className="login">
            <form onSubmit={handleSubmit} className="login-form">
                <span className="title">Accedi</span>
                <label className="email">
                    <span>Email</span>
                    <input type="email" required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    />
                </label>
                <label>
                    <span>Password</span>
                    <input type="password" required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    />
                </label>
                <div className="other-links">
                    <Link to="/passreset">Password dimenticata?</Link>
                    <Link to="/signup">Crea un nuovo account</Link>
                </div>
                {!isPending && <button className="bottone">Accedi</button>}
                {isPending && <button className="bottone" disabled>Caricamento</button>}
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    )
}