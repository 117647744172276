import { useEffect, useState } from "react"
import { updatePassword } from "firebase/auth"

export const useChangePass = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)

    const changePass = async (user, password) => {
        setError(null)
        setIsPending(true)

        try{
            //signup
            const res = await updatePassword(user, password)

            if(!res) {
                throw new Error("Impossibile cambiare la password")
            }

            if(!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch(err){
            if(!isCancelled) {
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(false);
    },[])

    return { error, isPending, changePass }
}