import { useState } from "react"
import { useSignup } from "../../hooks/useSignup"
import avatars from "../../assets/avatars/avatars"

//CSS
import "./Signup.css"
import { Link } from "react-router-dom";

export default function Signup(){
    const [selectedAvatar, setSelectedAvatar] = useState("car_blue.png");
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [display_name,setDisplayName] = useState("")
    const [isChecked, setIsChecked] = useState(false)
    const [formError, setFormError] = useState(null)
    const { signup, isPending, error} = useSignup()

    const handleSubmit = (e) => {
        e.preventDefault()
        setFormError(null)
        if(!email.endsWith("@studenti.unicz.it")){
            setFormError("L'email deve essere Istituzionale (@studenti.unicz.it)")
            return
        }
        if(!isChecked){
            setFormError("accetta i termini e le condizioni")
            return
        }
        signup(email, password, display_name, selectedAvatar)
    }

    return (
        <div className="signup">
            <form onSubmit={handleSubmit} className="signup-form">
                <Link to="/" className="backhome">indietro</Link>
                <span className="title">Registrati</span>
                <label>
                    <span>Icona Profilo</span>
                    <div className="avatars">
                        {Object.keys(avatars).map((key) => (
                            <img
                                key={key}
                                src={avatars[key]}
                                alt={key.replace(/_/g, ' ').replace(/\..+$/, '')}
                                className={`avatar ${selectedAvatar === key ? 'active' : ''}`}
                                onClick={() => setSelectedAvatar(key)}
                            />
                        ))}
                    </div>
                </label>
                <label>
                    <span>Email (Istituzionale)</span>
                    <input type="email" required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    />
                </label>
                <label>
                    <span>Password</span>
                    <input type="password" required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    />
                </label>
                <label>
                    <span>Nickname</span>
                    <input type="text" required maxLength={15} minLength={3}
                    onChange={(e) => setDisplayName(e.target.value)}
                    value={display_name}
                    />
                </label>
                <div className="conditions">
                    <label id="label-terms" htmlFor="terms">
                        <a 
                            href="/terms-and-conditions-spostiamociinsieme.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Accetto i termini e le condizioni
                        </a>
                    </label>
                    <input 
                        type="checkbox"
                        id="terms"
                        checked={isChecked}
                        onChange={(e) => {setIsChecked(e.target.checked)}}
                    />
                </div>
                {!isPending && <button className="bottone">Registrati</button>}
                {isPending && <button className="bottone" disabled>Loading</button>}
                {error && <p className="error">{error}</p>}
                {formError && <p className="error">{formError}</p>}
            </form>
        </div>
    )
}