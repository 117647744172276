import { useEffect, useState } from "react"
import { projectAuth, timestamp } from "../firebase/config"
import { connectAuthEmulator, createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import { useAuthContext } from "./useAuthContext"
import { projectFirestore } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { handleErrorCode } from "../utility/handleErrorCode";

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const signup = async (email, password, displayName, selectedAvatar) => {
        setError(null)
        setIsPending(true)

        try{
            //signup
            const res = await createUserWithEmailAndPassword(projectAuth, email, password)

            if(!res) {
                throw new Error("Could not complete signup")
            }

            const createdAt = timestamp.fromDate(new Date())

            //crea user
            const added_document = await setDoc(doc(projectFirestore,"utenti",res.user.uid), {
                viaggi: 0,
                displayName,
                photoURL: selectedAvatar,
                email,
                createdAt
            })

            //add display name && photo url
            await updateProfile(res.user, { displayName, photoURL: selectedAvatar })

            //dispatch login action
            dispatch({ type: "LOGIN", payload: res.user })

            if(!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch(err){
            if(!isCancelled) {
                console.log(err.message)
                setError(handleErrorCode(err.code))
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(false);
    },[])

    return { error, isPending, signup }
}