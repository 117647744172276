import "./Filterbar.css"
import { useState } from 'react'
import Location from "../assets/location.svg"

const filterList = ['Tutti', 'I miei annunci', 'Le mie corse']

export default function Filterbar({ changeFilter }){
  const [currentFilter, setCurrentFilter] = useState('Tutti')

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter)
    changeFilter(newFilter)
  }

  return (
    <div className="filterbar">
      {filterList.map((f) => (
        <button key={f} onClick={() => {handleClick(f)}} className={currentFilter === f ? "active" :''}>
          {f}
        </button>
      ))}
    </div>
  )
}