import { useEffect, useState } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select'
import { useLocation } from "react-router-dom";
import { increment } from "firebase/firestore"
import { timestamp } from "../../firebase/config";

//CSS
import "./NewTrip.css"

const veichle_options = [
    { value: 'Macchina', label: 'Macchina' },
    { value: 'Moto', label: 'Moto' },
]

const people_options = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 }
]

const fuel_options = [
    { value: 'Gratis' ,label: 'Gratis'},
    { value: '1' ,label: '1€'},
    { value: '2' ,label: '2€'},
    { value: '3' ,label: '3€'},
    { value: '4' ,label: '4€'},
    { value: '5' ,label: '5€'},
    { value: '10' ,label: '10€'},
    { value: '15' ,label: '15€'},
    { value: '20' ,label: '20€'}
]

export default function NewTrip(){
    const location = useLocation()

    const [i_place,setIPlace] = useState(location.state ? location.state.i_place : "");
    const [f_place,setFPlace] = useState(location.state ? location.state.f_place : "");
    const [date,setDate] = useState(location.state ? `${location.state.date.slice(6,10)}-${location.state.date.slice(3,5)}-${location.state.date.slice(0,2)}` : "");
    const [i_time,setITime] = useState(location.state ? location.state.i_time : "");
    const [f_time,setFTime] = useState(location.state ? location.state.f_time : "");
    const [veichle,setVeichle] = useState(location.state ? {value:location.state.veichle} : "");
    const [people,setPeople] = useState(location.state ? {value:location.state.people} : "");
    const [fuel,setFuel] = useState(location.state ? {value:location.state.fuel} : "");
    const [description,setDescription] = useState(location.state ? location.state.description : "");
    const [formError, setFormError] = useState(null)

    const { addTrip, updateDocument, response } = useFirestore("viaggi")
    const { user } = useAuthContext()
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0); // Resetta lo scroll alla parte superiore
    }, []);

    //handleNavigate
    useEffect(() => {
        if(response.success){
            navigate("/")
        }
    },[response])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormError(null)

        if (!veichle) {
            setFormError('Please select a Veichle')
            return
        }

        if (!people) {
            setFormError('Please select People')
            return
        }

        if (!fuel) {
            setFormError('Please select a Fuel')
            return
        }

        const createdBy = { 
            displayName: user.displayName, 
            photoURL: user.photoURL,
            id: user.uid
        }

        const date_time = timestamp.fromDate(new Date(`${date}T${i_time}`))

        if(location.state){
            const trip = {
                i_place,
                f_place,
                date_time,
                f_time,
                fuel: fuel.value,
                description
            }
            await updateDocument(location.state.id, trip)
        }
        else{
            const trip = {
                i_place,
                f_place,
                date_time,
                f_time,
                createdBy,
                people: people.value,
                veichle: veichle.value,
                fuel: fuel.value,
                people_booked: {},
                chat: [],
                description
            }
            
            await addTrip(trip, user.uid)
        }
    }

    return (
        <div className="newtrip">
            <form onSubmit={handleSubmit} className="newtrip-form">
                <Link to="/" className="backhome">indietro</Link>
                <span className="title">{location.state ? (<>Modifica Viaggio</>) : (<>Aggiungi Viaggio</>)}</span>
                <label>
                    <span>Luogo di Partenza</span>
                    <input type="text" required maxLength={30}
                    onChange={(e) => setIPlace(e.target.value)}
                    value={i_place}
                    />
                </label>
                <label>
                    <span>Luogo di Arrivo</span>
                    <input type="text" required maxLength={30}
                    onChange={(e) => setFPlace(e.target.value)}
                    value={f_place}
                    />
                </label>
                <label>
                    <span>Data</span>
                    <input type="date" required
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    />
                </label>
                <label>
                    <span>Orario di partenza</span>
                    <input type="time" required
                    onChange={(e) => setITime(e.target.value)}
                    value={i_time}
                    />
                </label>
                <label>
                    <span>Orario di Arrivo</span>
                    <input type="time" required
                    onChange={(e) => setFTime(e.target.value)}
                    value={f_time}
                    />
                </label>
                {!location.state && <label>
                    <span>Veicolo</span>
                    <Select options={veichle_options}
                        onChange={(option) => setVeichle(option)}
                        styles={{
                            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
                            option: (styles) => ({...styles, color: "black"})
                        }}
                        defaultValue={location.state ? location.state.veichle : ""}
                        placeholder={location.state ? location.state.veichle : ""}
                    />
                </label>}
                {!location.state && <label>
                    <span>Posti Disponibili</span>
                    <Select options={people_options}
                        onChange={(option) => setPeople(option)}
                        styles={{
                            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
                            option: (styles) => ({...styles, color: "black"})
                        }}
                        defaultValue={location.state ? location.state.people : ""}
                        placeholder={location.state ? location.state.people : ""}
                    />
                </label>}
                <label>
                    <span>Prezzo Carburante</span>
                    <Select options={fuel_options} 
                        onChange={(option) => setFuel(option)}
                        styles={{
                            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
                            option: (styles) => ({...styles, color: "black"})
                        }}
                        defaultValue={location.state ? location.state.fuel : ""}
                        placeholder={location.state ? location.state.fuel : ""}
                    />
                </label>
                <label>
                    <span>Descrizione (Opzionale)</span>
                    <textarea
                        onChange={(e) => setDescription(e.target.value)} maxLength={70}
                        value={description}
                    />
                </label>
                {!response.isPending && (<button className="bottone">{location.state ? (<>Aggiorna</>) : (<>Pubblica</>)}</button>)}
                {response.isPending && (<button className="bottone" disabled>Loading...</button>)}
                {formError && <p className="error">{formError}</p>}
            </form>
        </div>
    )
}