import "./Popup.css"

export default function Popup({children}){
  return (
    <div className="popup">
      <div className="popup-content">
        {children}
      </div>
    </div>
  )
}