import { useEffect, useState } from "react"
import { projectAuth } from "../firebase/config"
import { sendPasswordResetEmail } from "firebase/auth"

export const usePassReset = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)

    const passReset = async (email) => {
        setError(null)
        setIsPending(true)

        try{
            //signup
            const res = await sendPasswordResetEmail(projectAuth, email)

            if(!res) {
                throw new Error("Could not complete signup")
            }

            if(!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch(err){
            if(!isCancelled) {
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(false);
    },[])

    return { error, isPending, passReset }
}