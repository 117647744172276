import { useRef, useState } from "react"
import { projectFirestore, timestamp } from "../firebase/config"
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";

export const useCollection = (custom_collection, _custom_query) => {
    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    const custom_query = useRef(_custom_query).current

    let ref = collection(projectFirestore, custom_collection)

    //getDocuments
    const getDocuments = async () => {
        //query
        const now = new Date();
        const two_hours_later = new Date(now);
        two_hours_later.setHours(now.getHours() - 2);
        const currentDateTime = timestamp.fromDate(two_hours_later);
        ref = query(
            ref,
            where("date_time", ">=", currentDateTime),
            // orderBy("date_time", "asc"),
            // orderBy("createdAt", "desc")
          );

        if(custom_query){
            //ref = query(ref,where(...custom_query), orderBy("createdAt", "desc"))
        }

        try{
            const snapshot = await getDocs(ref);
            let results = []
            snapshot.forEach(doc => {

                const data = doc.data()
                const dateTime = data.date_time.toDate();
                const day = String(dateTime.getDate()).padStart(2, '0');
                const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // getMonth() restituisce 0-11
                const year = dateTime.getFullYear();
                const hours = String(dateTime.getHours()).padStart(2, '0');
                const minutes = String(dateTime.getMinutes()).padStart(2, '0');

                const formattedDate = `${day}-${month}-${year}`;
                const formattedTime = `${hours}:${minutes}`;

                results.push({
                    ...data,
                    id: doc.id,
                    date: formattedDate,
                    i_time: formattedTime
                })
            });
            
            setDocuments(results)
            setError(null)
        }
        catch(err){
            setDocuments(null)
            setError(err.message)
            console.log(err.message)
        }
    }

    // useEffect(() => {

    //     //collection ref
    //     let ref = collection(projectFirestore, custom_collection)

    //     //query
    //     if(custom_query){
    //         ref = query(ref,where(...custom_query), orderBy("createdAt", "desc"))
    //     }
        
    //     const unsubscribe = onSnapshot(ref, (snapshot) => {
    //         let results = []
    //         snapshot.docs.forEach(doc => {
    //             results.push({ ...doc.data(), id: doc.id })
    //         })

    //         //update states
    //         setDocuments(results)
    //         setError(null)
            
    //     }, (error) => {
    //         console.log(error)
    //         setError("Could not fetch the data")
            
    //     })

    //     //unsubscrive
    //     return () => unsubscribe()

    // }, [custom_collection, custom_query])

    return { documents, error, getDocuments }
}