export const handleErrorCode = (code) => {
  switch(code){
    case "auth/invalid-credential":
        return "Le credenziali non sono valide"
    case "auth/email-already-in-use":
        return "L'email è già stata utilizzata"
    case "auth/weak-password":
        return "Password troppo debole"
    default:
      return ""
  }
}