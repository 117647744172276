import { useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext"
import HomeImage from "../../assets/launchscreen/launchscreen1.jpeg"
import HomeImage2 from "../../assets/launchscreen/launchscreen2.jpeg"
import HomeImage3 from "../../assets/launchscreen/launchscreen3.jpeg"
import HomeImage4 from "../../assets/launchscreen/launchscreen4.jpeg"
import LogoInsieme from "../../assets/logo-insieme.png"

//CSS
import "./LaunchScreen.css"
import { Carousel } from "../../components/Carousel";

export default function LaunchScreen(){
  const { dispatch } = useAuthContext()
  
  //handleRefresh
  useEffect(() => {
    // This runs when the component is mounted (after the page loads).
    if (localStorage.getItem('skipLaunchScreen') === 'true') {
      //Dispatch
      dispatch({ type: "LAUNCHSCREEN", launch_screen: false })
      localStorage.removeItem('skipLaunchScreen');
    }
    if(localStorage.getItem('chat_id')){
      const chat_id = localStorage.getItem('chat_id')
      dispatch({ type: "CHAT", chat_id })
      localStorage.removeItem('chat_id');
    }
  }, []);

  const handleLaunchScreen = (e) => {
    e.preventDefault()
    dispatch({ type: "LAUNCHSCREEN", launch_screen: false })
  }

  return (
    <div className="launch-screen">
      <Carousel data={[HomeImage,HomeImage2,HomeImage3,HomeImage4]} />
      <div className="title-wrap">
        <img src={LogoInsieme} alt="logo-insieme" className="logo"></img>
        <div><div className="title">Spostiamoci #INSIEME</div></div>
      </div>
      <div className="description">
      La nostra associazione, Insieme per gli studenti, è da oltre 10 anni in prima linea nella tutela dei diritti degli studenti universitari.
      Ogni giorno ci battiamo con determinazione per  
garantire che ogni studente riceva il supporto necessario
per affrontare e risolvere le sfide del percorso universitario.
Il nostro impegno si traduce in una vasta gamma di servizi,
pensati per rispondere a tutte le esigenze degli studenti. Spostiamoci insieme è proprio uno di questi! Pensato per potersi muovere in compagnia, dando una mano a chi purtroppo non è in possesso di un’automobile.
Inoltre, anche la sostenibilità rientra tra gli obiettivi di Spostiamoci Insieme, essendo una priorità a cui pensare. Cosa aspetti? Cerca un posto o offri un passaggio ai tuoi colleghi!
      </div>
      <button className="button" onClick={handleLaunchScreen}>Entra nel sito</button>
    </div>
  )
}