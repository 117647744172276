//CSS
import "./TripList.css"
import TripCardFront from "./TripCardFront"
import TripCardBack from "./TripCardBack"
import { useState } from "react"
import PopupMessage from "./PopupMessage"

export default function TripList({ trips }) {

    const [flipped, setFlipped] = useState(null)
    const [showPopupError, setShowPopupError] = useState(false)

    const handleFlip = (e,index) => {
        if(e.target.localName === "button" || e.target.localName === "img") return
        if(index === flipped){
            setFlipped(null)
            return
        }
        if(window.screen.width < 800){
            setFlipped(index)
        }
    }

    return (
        <div className="trips">
            {trips.map((trip, index) => (
                <div className="flip-card" key={trip.id} onClick={(e) => {handleFlip(e,index)}}>
                    <div className="flip-card-inner" style={flipped===index ? {transform: "rotateY(180deg)"} :{}}>
                        <div className="flip-card-front">
                            <TripCardFront trip={trip} />
                        </div>
                        <div className="flip-card-back">
                            <TripCardBack trip={trip} setShowPopupError={setShowPopupError} /> 
                        </div>
                    </div>
                </div>
            ))}
            {showPopupError && <PopupMessage message={"Ops, qualcosa è andato storto"} button_title={"Ricarica"} callBack={() => {localStorage.setItem('skipLaunchScreen', true); window.location.reload();}}></PopupMessage>}
        </div>
    )
}