import { useAuthContext } from "../../hooks/useAuthContext"
import { useCollection } from "../../hooks/useCollection"
import TripList from "../../components/TripList"
import Filterbar from "../../components/Filterbar"
import Popup from "../../components/Popup"
import { useEffect, useState } from "react"
import Hero from "../../components/Hero"
import Loading from "../../components/Loading"


//CSS
import "./Home.css"

export default function Home(){
    const { user } = useAuthContext()
    const { documents, error, getDocuments} = useCollection("viaggi")
    const [filter, setFilter] = useState('Tutti')

    const changeFilter = (newFilter) => {
        setFilter(newFilter)
    }

    const trips = documents ? documents.filter(document => {
        switch(filter) {
          case 'Tutti':
            return true
          case 'I miei annunci':
            return document.createdBy.id == user.uid
          case 'Le mie corse':
            let assignedToMe = false
            
            Object.keys(document.people_booked).forEach(u => {
              if(u.id === user.uid) {
                assignedToMe = true
              }
              if(u == user.uid) {
                assignedToMe = true
              }
            })
            return assignedToMe
          default:
            return true
        }
      }) : null

    useEffect(() => {
        getDocuments()
    },[])

    return (
        <div className="home">
            {error && <p>{error}</p>}
            <Hero />
            {documents && <Filterbar changeFilter={changeFilter}/>}
            <div className="dashboard">
                {trips && trips.length==0 && (<div className="empty-trips"><span className="">Nessun viaggio disponibile!</span></div>)}
                {documents && <TripList trips={trips} />}
                {!documents && <Loading />}
            </div>
        </div>
    )
}