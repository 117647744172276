import { useAuthContext } from "../hooks/useAuthContext"
import { useFirestore } from "../hooks/useFirestore"
import { increment, arrayUnion, deleteField } from "firebase/firestore"
import { useEffect, useState } from "react"

//CSS
import "./TripCardBack.css"
import Chat from "../assets/chat.svg"
import Trash from "../assets/trash.svg"
import { Link } from "react-router-dom"

export default function TripCardBack({ trip, setShowPopupError }){
  const { user, dispatch } = useAuthContext()
  const { updateDocument, deleteDocument, deleteTrip, response } = useFirestore("viaggi")
  const [messageConfirm, setMessageConfirm] = useState("")
  const [showConfirm, setShowConfirm] = useState(false)

  //handleResponse
  useEffect(() => {
    if(response.success && !response.error){
      localStorage.setItem('skipLaunchScreen', true);
      //localStorage.setItem('chat_id', chat_id);
      window.location.reload();
    }

    if(response.error){
      setShowPopupError(true)
    }
  },[response, setShowPopupError])

  //Book
  const handleBook = async (e, chat_id) => {
    e.preventDefault();

    const message = {
      displayName: user.displayName,
      photoURL: user.photoURL,
      uid: user.uid,
      text: "Ciao, mi sono unito a questo viaggio",
      id_msg: Math.random().toString(16).slice(2)
    }

    const update_message = {
      people: increment(-1),
      chat: arrayUnion(message)
    }

    update_message["people_booked."+user.uid] = user.displayName

    await updateDocument(trip.id,update_message)
  }

  //Booked
  const handleBooked = async () => {

    const message = {
      displayName: user.displayName,
      photoURL: user.photoURL,
      uid: user.uid,
      text: "Ciao, ho lasciato questo gruppo!",
      id_msg: Math.random().toString(16).slice(2)
    }

    const update_message = {
      people: increment(1),
      chat: arrayUnion(message)
   }

   update_message["people_booked."+user.uid] = deleteField()

   await updateDocument(trip.id,update_message)
  }

  const handleConfirm = () => {
    switch(messageConfirm){
      case "disdici":
        handleBooked()
        break
      case "elimina":
        handleDelete()
        break
      default:
        setShowConfirm(false)
    }
  }

  //Delete
  const handleDelete = async () => {
    await deleteTrip(trip.id, user.uid)
  }

  //handleChat
  const handleChat = (e, chat_id) => {
    e.preventDefault()
    dispatch({ type: "CHAT", chat_id })
  }

  return (
    <div className="trip-card-back">
      <div className="description">
        <div className="description-title">Descrizione</div>
        <div className="description-content">{trip.description ? trip.description : "Nessuna descrizione per questo viaggio!"}</div>
      </div>
      <div className="buttons">
        {!showConfirm && response && !response.isPending && trip.people > 0 && trip.createdBy.id !== user.uid && trip.people_booked && !(user.uid in trip.people_booked) && <button className="button-book" onClick={(e) => {handleBook(e,trip.id)}}>prenota</button>}
        {!showConfirm && response && !response.isPending && trip.people_booked && user.uid in trip.people_booked && trip.createdBy.id !== user.uid && <button className="button-booked" onClick={() => {setMessageConfirm("disdici");setShowConfirm(true);}}>disdici</button>}
        {!showConfirm && response && !response.isPending && trip.people <= 0 && trip.people_booked && !(user.uid in trip.people_booked) && trip.createdBy.id !== user.uid && <button className="button-soldout" disabled>esaurito</button>}
        {!showConfirm && response && !response.isPending && trip.createdBy.id === user.uid && (<div className="edit"><Link to="/newtrip" state={trip} className="button-edit">modifica</Link><button onClick={() => {setMessageConfirm("elimina");setShowConfirm(true);}}><img src={Trash} alt="Trash" className="button-delete"></img></button></div>)}
        {showConfirm && response && !response.isPending && (<div className="confirm">
          <button className="button-confirm-no" onClick={() => {setMessageConfirm("");setShowConfirm(false)}}>annulla</button>
          <button className="button-confirm-yes" onClick={handleConfirm}>{messageConfirm}</button>
        </div>)}
        {response && response.isPending && <button className="button-confirm-no" disabled>caricamento</button>}
        {((trip.people_booked && user.uid in trip.people_booked) || trip.createdBy.id === user.uid) &&(
          <button className="chat" onClick={(e) => {handleChat(e,trip.id)}}>
            <img src={Chat} alt="Chat" className="chat-icon"></img>
            <span>chat</span>
          </button>
        )}
      </div>
    </div>
  )
}